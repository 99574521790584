import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  emailValue: any;
  token: any;
  enablePasswordField: boolean;
  btnConfirmPasswordShowHide: boolean;
  isError: boolean;
  errorMessage : string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resetText: string = configJSON.resetText;
  verifyText: string = configJSON.verifyText;
  mailsendText: string = configJSON.mailsendText;
  invalidEmailText: string = configJSON.invalidEmailText;
  emailLabelText : string = configJSON.emailLabelText;
  isButtonEnabled :boolean = false;
  sendVerifyAPICallId : any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {

        emailValue: "",
        token: "",
        enablePasswordField: true,
        btnConfirmPasswordShowHide: true,
        isError : false,
        errorMessage : ""
      };
      this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
      ];
      this.receive = this.receive.bind(this);
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
   // Customizable Area Start
   // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.sendVerifyAPICallId &&
        this.sendVerifyAPICallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ){
        let responseJson  = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if(responseJson && responseJson.error){
            this.setState({
                isError : true,
                errorMessage : configJSON.invalidEmailText,
            })
            return;
          }
        localStorage.setItem("otpStatus","reset");
        localStorage.setItem("email",this.state.emailValue);
        localStorage.setItem("phoneNumber", responseJson.meta?.account?.phone_number || "");
        window.location.href = "/OTPInputAuth";
      }
    // Customizable Area End
  }
  // Customizable Area Start
  sendMailtoVerify = () => {
    this.setState({
        isError:false,
        errorMessage:""
    })
    const header = {
        "Content-Type": configJSON.forgotPasswordAPiContentType,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const httpBody = {
        email: this.state.emailValue,
      }
      this.sendVerifyAPICallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.webSendEmailAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      )
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
