// Customizable Area Start
import React from "react"; 
import { Input, Button } from '@builder/component-library';
import { Box, Typography, styled, Checkbox } from "@mui/material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from "@mui/icons-material/Remove";
import i18n from "../../../components/src/i18nextConfig/i18n";
import NewPasswordController, {
  Props,
} from "./NewPasswordController.web";
// Customizable Area End
export default class NewPassword extends NewPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { t } = i18n; 
    return (
      <>
      <Main>
          <Body>
            <HeadContainer>
              <Header variant="h4" >
                { t (this.newTitle) }
              </Header>
              
              <SubHeader variant="h1">
                {
                  t(this.createText)
                }
              </SubHeader>

            </HeadContainer>
            <FormWrapper>
              <InputWrapper>
                <Label>{t(this.newTitle)}</Label>
                <Input
                  data-test-id="txtInputPassword"
                  secureTextEntry={this.state.enablePasswordField}
                  placeholder={t(this.newTitle)}
                  value={this.state.password}
                  onChangeText={(text) => {
                    this.setPassword(text);

                  }}
                  rightIcon={
                    <Button
                      style={{ backgroundColor: "transparent" }}
                      data-test-id="btnPasswordShowHide"
                      aria-label="toggle password visibility"
                      onPress={this.handleClickShowPassword}
                      icon={this.state.enablePasswordField ? (
                        <VisibilityIcon style={{   color:"#94A3B8"}}/>
                      ) : (
                        < VisibilityOffIcon style={{ color:"#94A3B8" }}/>
                      )}
                    />
                  } 
                />
              </InputWrapper>

              <CustomCheckbox>
              <Box display="flex" alignItems="center">
                  <Checkbox
                  checked={this.state.checkedLetter}
                  inputProps={{ 'aria-label': 'controlled' }}
                  icon={
                    this.state.password.length > 0 && !this.state.checkedLetter ? (
                      <RemoveIcon sx={{ color: '#ffffff' }} /> 
                    ) : undefined 
                  }
                  checkedIcon={
                    <CheckIcon sx={{ color: '#ffffff',backgroundColor:'#059669',borderRadius:'4px' }} /> 
                  }
                  sx={
                    this.state.password.length > 0 && !this.state.checkedLetter
                      ? redMinusStyle
                      : {}
                  }
                  />

                  <CheckBoxText>
                     { t(this.passwordCondition1) } 
                  </CheckBoxText>
                </Box>
                <Box display="flex" alignItems="center">
                  <Checkbox
                    data-test-id={"btnRememberMe"}
                    style={{ margin: 1 }}
                    title=""
                    checked={this.state.checkedLength}
                    icon={
                      this.state.password.length > 0 && !this.state.checkedLength ? (
                        <RemoveIcon sx={{ color: '#ffffff' }} /> 
                      ) : undefined 
                    }
                    checkedIcon={
                      <CheckIcon sx={{ color: '#ffffff',backgroundColor:'#059669',borderRadius:'4px' }} /> 
                    }
                    sx={
                      this.state.password.length > 0 && !this.state.checkedLength
                        ? redMinusStyle
                        : {}
                    }
                
                  />
                  <CheckBoxText> 
                     { t(this.passwordCondition2)}
                  </CheckBoxText>
                </Box>
                <Box display="flex" alignItems="center">
                <Checkbox
                    data-test-id={"btnRememberMe"}
                    title=""
                    style={{ margin: 1 }}
                    checked={this.state.password.length > 0 && this.state.checkedDigit}
                    icon={
                      this.state.password.length > 0 && !this.state.checkedDigit ? (
                        <RemoveIcon sx={{ color: '#ffffff' }} /> 
                      ) : undefined 
                    }
                    checkedIcon={
                      <CheckIcon sx={{ color: '#ffffff',backgroundColor:'#059669',borderRadius:'4px' }} /> 
                    }
                    sx={
                      this.state.password.length > 0 && !this.state.checkedDigit
                        ? redMinusStyle
                        : {}
                    }
                  />
                  <CheckBoxText> { t(this.passwordCondition3)}</CheckBoxText>
                </Box>
                </CustomCheckbox>
              <ButtonsWrapper>
                <Button
                  data-test-id={"btnEmailLogIn"}
                  style={{ backgroundColor: "#00496B", }}
                  text= {t(this.btnText) } 
                  onPress={() => this.createNewPassword()}
                />

                </ButtonsWrapper>
            </FormWrapper>
          </Body>
        </Main>
      </> 
    );
    // Customizable Area End
  }

}
// Customizable Area Start
const Main = styled(Box)({
    padding: "20px",
    display: "flex",
    alignItems: "center",
    background: "#E9F6FF",
    justifyContent: "center",
    height:"calc(100vh - 130px)",
    fontFamily: "Roboto",
  });

  const Body = styled(Box)({
    background: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px 10px",
    borderRadius: "10px",
    width: "100%",
    maxWidth: "420px",
    justifyContent: "space-between",
    height:"90%"
  });
  const HeadContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px", 
  })
  const Header = styled(Typography)({
    fontSize: "24px",
    color: "#053348",
    fontWeight: 800,
  });
  const SubHeader = styled(Typography)({
    fontSize: "16px",
    fontWeight: 500,
    color: "#053348",
  });

  const FormWrapper = styled(Box)({
    display: "flex",
    width: "100%",
    paddingTop: "20px",
    flexDirection: "column",
  });
  const InputWrapper = styled(Box)({
    flex: 1,
    padding: "10px 20px",
    flexDirection: "column",
  });
  const CustomCheckbox = styled(Box)({
    display:"flex",
    flexDirection:"column",
    padding:"10px",
  })
  const redMinusStyle = {
    '& .MuiSvgIcon-root': {
      backgroundColor: '#E4022E',
      borderRadius: '4px',
    },
  };
  const CheckBoxText = styled(Typography)({
    fontSize: "12px",
    color: "#053348",
  });
  const Label = styled(Typography)({
    fontSize: "16px",
    fontWeight: 500,
    color: "#053348",
    marginBottom: "5px",
  });
  
  const ButtonsWrapper = styled(Box)({
    flex: 1,
    padding: "20px",
  });
// Customizable Area End

