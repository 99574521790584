import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
export const language = require("./languages/language.json");

const resources = {
  en: { translation: language.en },
  de: { translation: language.de },
  nl: { translation: language.nl },
};

i18n
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v2',
    resources,
    lng: 'en', 
    fallbackLng: 'en', 
    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;