import React from "react";
// Customizable Area Start
import { 
  Box, Typography, Table, 
  TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper,
  Card, CardContent, 
  styled, CardHeader, IconButton, Collapse
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {arrowImage} from "./assets"
import i18n from "../../../components/src/i18nextConfig/i18n";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { withSearch as withSearchHOC} from '../../../components/src/SearchContext';
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderSingleDepartment = () => {
    return (
      <Box>
      <TableContainer component={Paper} sx={{ marginTop: 3, maxHeight: 'calc(80vh - 130px)', overflowY: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <CustomTableCell>{i18n.t("Department")}</CustomTableCell>
                <CustomTableCell>{i18n.t("Device")}</CustomTableCell>
                <CustomTableCell>{i18n.t("Device user")}</CustomTableCell>
                <CustomTableCell>{i18n.t("Service")}</CustomTableCell>
                <CustomTableCell>{i18n.t("Article")}</CustomTableCell>
                <CustomTableCell>{i18n.t("Forwarding")}</CustomTableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.singleDepartmentDeviceUser.length > 0 ? (
                this.state.singleDepartmentDeviceUser.map((device: any, index: number) => (
                  <TableRow
                    key={index}
                    data-test-id={`device-row-${index}`}
                    onClick={this.handleSingleDevice(device.id__,index+1, device.device)}
                  >
                    <CustomTableCellDark>{device.department}</CustomTableCellDark>
                    <CustomTableCellDark>{device.device}</CustomTableCellDark>
                    <CustomTableCellDark>{device.device_user}</CustomTableCellDark>
                    <CustomTableCellDark>{device.service}</CustomTableCellDark>
                    <CustomTableCellDark>{device.article}</CustomTableCellDark>
                    <CustomTableCellDark>{device.forwarding}</CustomTableCellDark>
                    <TableCell align="right">
                      <Typography variant="h6" component="span" sx={{ cursor: 'pointer', color: "#00496B" }}>
                        <KeyboardArrowRightIcon />
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">{i18n.t("No devices found")}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };
  
  renderDepartmentDetails = () => {
    return (
      <TableContainer component={Paper} sx={{ marginTop: 3}}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <CustomTableCell>{i18n.t("Department Name")}</CustomTableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
        </Table>
        <Box sx={{ maxHeight: "calc(80vh - 120px)", overflowY: "auto" }}>
          <Table>
            <TableBody>
              {this.state.dashboardDepartments.length > 0 ? (
                this.state.dashboardDepartments.map((department: any, index: any) => (
                  <TableRow
                    key={index}
                    data-test-id={`department-row`}
                    onClick={() => this.handleDepartmentClick(department.department)}
                  >
                    <CustomTableCellDark>{department.department}</CustomTableCellDark>
                    <TableCell align="right" sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        variant="h6"
                        component="span"
                        sx={{ cursor: 'pointer', color: "#00496B" }}
                      >
                        <KeyboardArrowRightIcon/>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2} align="center">{i18n.t("No departments found")}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    );
  };
  
  handleExpandClick = (section: string,forwarding :string) => () => {
    const userPermission = ['europac',"securitas fe","securitas de"]
    if(section === "expandedDevice") {
      this.setState({ expandedDevice: !this.state.expandedDevice });
    }
    else if(section === "expandedARC" && userPermission.includes(forwarding.toLowerCase()) ){
      this.setState({ expandedARC: !this.state.expandedARC });
    }
    else if(section === "expandedNotification"){
      this.setState({ expandedNotification: !this.state.expandedNotification});
    }
  }
  renderAlaramNotification = (notification : any,device : any,connection : any,failureNotification : any) => {
    return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{backgroundColor: "#F5F5F4"}}>
            <CustomHeaderTableCell >{i18n.t("Alarm notification mail")}</CustomHeaderTableCell>
            <CustomHeaderTableCell >{i18n.t("Name")}</CustomHeaderTableCell>
            <CustomHeaderTableCell>{i18n.t("Mail")}</CustomHeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notification.map((contact: any, index: any) => (
            <TableRow key={index}>
              <CustomTableDeviceCellValue></CustomTableDeviceCellValue>
              <CustomTableDeviceCellValue>{contact.name}</CustomTableDeviceCellValue>
              <CustomTableDeviceCellValue>{contact.email}</CustomTableDeviceCellValue>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Table>
        <TableHead>
          <TableRow sx={{backgroundColor: "#F5F5F4"}}>
            <CustomHeaderTableCell >{i18n.t("Day's no connection")}</CustomHeaderTableCell>
            <CustomHeaderTableCell >{device.number_of_days_no_connection}</CustomHeaderTableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Table sx={{marginTop: "10px"}}>
        <TableHead>
          <TableRow sx={{backgroundColor: "#F5F5F4"}}>
            <CustomHeaderTableCell >{i18n.t("No connection mail")}</CustomHeaderTableCell>
            <CustomHeaderTableCell >{i18n.t("Name")}</CustomHeaderTableCell>
            <CustomHeaderTableCell>{i18n.t("Mail")}</CustomHeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {connection.map((contact: any, index: any) => (
            <TableRow key={index}>
              <CustomTableDeviceCellValue></CustomTableDeviceCellValue>
              <CustomTableDeviceCellValue>{contact.name}</CustomTableDeviceCellValue>
              <CustomTableDeviceCellValue>{contact.email}</CustomTableDeviceCellValue>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Table >
        <TableHead>
          <TableRow sx={{backgroundColor: "#F5F5F4"}} >
            <CustomHeaderTableCell >{i18n.t("Failure notification mail")} <br/>{i18n.t("of sms")}</CustomHeaderTableCell>
            <CustomHeaderTableCell >{i18n.t("Name")}</CustomHeaderTableCell>
            <CustomHeaderTableCell>{i18n.t("Mail")}</CustomHeaderTableCell>
            <CustomHeaderTableCell>{i18n.t("Phone Number")}</CustomHeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {failureNotification.map((contact: any, index: any) => (
            <TableRow key={index}>
              <CustomTableDeviceCellValue></CustomTableDeviceCellValue>
              <CustomTableDeviceCellValue>{contact.name}</CustomTableDeviceCellValue>
              <CustomTableDeviceCellValue>{contact.email}</CustomTableDeviceCellValue>
              <CustomTableDeviceCellValue>{contact.phone_number}</CustomTableDeviceCellValue>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
  renderEmergencyContacts = (contacts: any) => (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <CustomHeaderTableCell >{i18n.t("Emergency Contact")}</CustomHeaderTableCell>
            <CustomHeaderTableCell >{i18n.t("Name")}</CustomHeaderTableCell>
            <CustomHeaderTableCell>{i18n.t("Phone Number 1")}</CustomHeaderTableCell>
            <CustomHeaderTableCell>{i18n.t("Phone Number 2")}</CustomHeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts.map((contact: any, index: any) => (
            <TableRow key={index}>
              <CustomTableDeviceCellValue></CustomTableDeviceCellValue>
              <CustomTableDeviceCellValue>{contact.name}</CustomTableDeviceCellValue>
              <CustomTableDeviceCellValue>{contact.tell}</CustomTableDeviceCellValue>
              <CustomTableDeviceCellValue>{contact.tell2}</CustomTableDeviceCellValue>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
  
  renderUserDetails = () => {
    const { expandedDevice, expandedARC,SingleDeviceDataList,expandedNotification } = this.state;
    const device = Array.isArray(SingleDeviceDataList) && SingleDeviceDataList[0];
    return (
      device &&(
        <Box>
      <Card sx={{ maxWidth: '100vw',margin: '20px auto'}}>
        <CustomCardHeader
          title={i18n.t("Device and User")}
          action={
            <CustomCloseIconButton
              data-test-id="custom-close-icon-button"
              onClick={this.handleExpandClick('expandedDevice',device.forwarding)}
              aria-expanded={expandedDevice}
              aria-label="show more"
            >
              {expandedDevice &&<ExpandMoreIcon /> } 
            </CustomCloseIconButton>
          }
        />
        <ButtonContainer >
          <CustomOpenIconButton
                onClick={this.handleExpandClick('expandedDevice',device.forwarding)}
                aria-expanded={expandedDevice}
                aria-label="show more"
              >
              {!expandedDevice &&<ExpandMoreIcon sx={{textAlign : "end"}}/> } 
            </CustomOpenIconButton>
          </ButtonContainer>
        <Collapse in={expandedDevice} timeout="auto" unmountOnExit>
          <CardContent sx={{ marginLeft: '', marginRight: "" ,borderRadius : "10px"}}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <CustomTableDeviceCell >{i18n.t("Device ID")}:</CustomTableDeviceCell>
                    <CustomTableDeviceCellValue >{device.device_id}</CustomTableDeviceCellValue >
                  </TableRow>
                  <TableRow>
                    <CustomTableDeviceCell>{i18n.t("User Name")}:</CustomTableDeviceCell>
                    <CustomTableDeviceCellValue >{device.device_user}</CustomTableDeviceCellValue >
                  </TableRow>
                  <TableRow>
                    <CustomTableDeviceCell>{i18n.t("Phone Number")}:</CustomTableDeviceCell>
                    <CustomTableDeviceCellValue >{device.phone_number}</CustomTableDeviceCellValue >
                  </TableRow>
                  <TableRow>
                    <CustomTableDeviceCell >{i18n.t("Forwarding")}:</CustomTableDeviceCell>
                    <CustomTableDeviceCellValue >{device.forwarding}</CustomTableDeviceCellValue >
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Collapse>
        </Card>
        <Card sx={{ maxWidth: '100vw',margin: '20px auto'}} >
          <CustomCardHeader
            title="ARC"
            action={
              <CustomCloseIconButton
              data-test-id="custom-close-icon-button"
                onClick={this.handleExpandClick('expandedARC',device.forwarding)}
                aria-expanded={expandedARC}
                aria-label="show more"
              >
                {expandedARC && <ExpandMoreIcon />}
              </CustomCloseIconButton>
            }
          />
          <ButtonContainer >
            <IconButton
                  onClick={this.handleExpandClick('expandedARC',device.forwarding)}
                  aria-expanded={expandedARC}
                  aria-label="show more"
                  sx={{
                                  
                    color: '#053348',
                    textAlign : "end"
                  }}
                >
                {!expandedARC &&<ExpandMoreIcon sx={{textAlign : "end"}}/> } 
              </IconButton>
          </ButtonContainer>
          <Collapse in={expandedARC} timeout="auto" unmountOnExit>
            <CardContent >
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <CustomTableDeviceCell >{i18n.t("Potential Threat")}:</CustomTableDeviceCell>
                      <CustomTableDeviceCellValue>{device.potential_threat}</CustomTableDeviceCellValue>
                    </TableRow>
                    <TableRow>
                      <CustomTableDeviceCell>{i18n.t("Extra Information")}:</CustomTableDeviceCell>
                      <CustomTableDeviceCellValue>{device.extra_information}</CustomTableDeviceCellValue>
                    </TableRow>
                    <TableRow>
                      <CustomTableDeviceCell>{i18n.t("Risk Object")}:</CustomTableDeviceCell>
                      <CustomTableDeviceCellValue>{device.risk_object }</CustomTableDeviceCellValue>
                    </TableRow>
                    <TableRow>
                      <CustomTableDeviceCell>{i18n.t("Address")}:</CustomTableDeviceCell>
                      <CustomTableDeviceCellValue>{device.address}</CustomTableDeviceCellValue>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              </CardContent>
              <CardContent> {this.renderEmergencyContacts(device.emergency_contact)}</CardContent>
              <CardContent>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <CustomTableDeviceCell>{i18n.t("Password")}:</CustomTableDeviceCell>
                      <CustomTableDeviceCellValue>{device.password}</CustomTableDeviceCellValue>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              </CardContent>
          </Collapse> 
        </Card>
        <Card>
          <CustomCardHeader
            title={i18n.t("Notifications")}
            action={
              <CustomCloseIconButton
              data-test-id="custom-close-icon-button"
                onClick={this.handleExpandClick('expandedNotification',device.forwarding)}
                aria-expanded={expandedNotification}
                aria-label="show more"
              >
                {expandedNotification && <ExpandMoreIcon />}
              </CustomCloseIconButton>
            }
          />
          <ButtonContainer >
            <IconButton
                  onClick={this.handleExpandClick('expandedNotification',device.forwarding)}
                  aria-expanded={expandedNotification}
                  aria-label="show more"
                  sx={{
                                  
                    color: '#053348',
                    textAlign : "end"
                  }}
                >
                {!expandedNotification &&<ExpandMoreIcon sx={{textAlign : "end"}}/> } 
              </IconButton>
          </ButtonContainer>
          <Collapse in={expandedNotification} timeout="auto" unmountOnExit>
            <CardContent> {this.renderAlaramNotification (device.alarm_notification_mail,device,device.no_cannection_mail,device.failure_notification_mail_of_sms)}</CardContent>
          </Collapse>
        </Card>
        <Box sx={{ display: "flex", width: "100%", justifyContent: "end", alignItems: "center" }}>
          <CustomButton onClick={this.handlePreviousClick} disabled={!this.state.isPreviousButton} sx={{ display: "flex", alignItems: "center" }}>
            <KeyboardArrowLeftIcon sx={{ display: "inline", position: "relative", marginRight: "8px" }} />
            {i18n.t("Previous Device")}
          </CustomButton>
          <CustomButton onClick={this.handleNextClick} disabled={!this.state.isNextButton} sx={{ display: "flex", alignItems: "center", marginLeft: "16px" }}>
          {i18n.t("Next Device")}
          <KeyboardArrowRightIcon sx={{ display: "inline", position: "relative", marginLeft: "8px" }} />
          </CustomButton>
        </Box>
      </Box>
      )
    )
  }
  renderDashBoradComponent = () => {
    let content;
    if (this.state.selectedDepartmentUser) {
        content = this.renderUserDetails();
    } else if (this.state.selectedDepartment) {
        content = this.renderSingleDepartment();
    } else if(this.state.singleDepartmentDeviceUser){
        content = this.renderDepartmentDetails();
    }
    return content;
  }
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainContainer >
        <Card variant="outlined">
        <CardContent sx = {{display : "flex",color: "#053348"}}>
            <CustomTypoText variant="h5" sx={{position:"relative",top:"4px"}} onClick = {() => this.getDashboardData()}>
              {i18n.t("Departments Overview")}
            </CustomTypoText>
           {this.state.selectedDepartment && 
           <CustomTypoText variant="h5"  onClick = {() => this.handleDepartmentClick(this.state.selectedDepartment)}>
            <ArrowImage src = {arrowImage.default} alt="arrow"/>
              {this.state.selectedDepartment} 
            </CustomTypoText>}
           { this.state.selectedDepartmentUser &&
           <CustomTypoText variant="h5">
            <ArrowImage src = {arrowImage.default} alt="arrow"/>
              {this.state.selectedDepartmentUser } 
            </CustomTypoText>}
          </CardContent>
        </Card>
        <Box sx={{ marginTop: 2 }} />
        {this.renderDashBoradComponent()}
      </MainContainer>
    );
    // Customizable Area End
  }
}
export default withSearchHOC(Dashboard);
// Customizable Area Start
const CustomTableCell = styled(TableCell)({
  color: '#053348',
  position: 'sticky',
});
const CustomTableCellDark = styled(TableCell)({
  color: '#0F172A',
});
const CustomTypoText = styled(Typography)({
  cursor: "pointer",
})
const MainContainer = styled(Box)({
  padding: 3, backgroundColor: '#E9F6FF',
  margin: "10px",
  borderRadius: "10px"
})
const ArrowImage = styled("img")({
  height:"30px",
  width: "30px",
  position: "relative",
  top: "6px",
  display: "inline"
})
const CustomTableDeviceCell = styled(TableCell)({
  fontWeight: 'bold', minWidth: '150px', 
  color:"#053348",
  fontSize : "14px",
  fontFamily : "Roboto"
})
const CustomTableDeviceCellValue = styled(TableCell)({
  color: "#053348"
})
const ButtonContainer = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection:"row-reverse"
})
const CustomCardHeader = styled(CardHeader)({
  backgroundColor: "#053348",
  color: '#fff',
  fontWeight: 'bold',
  borderTopLeftRadius : "10px",
  borderTopRightRadius : "10px"
})
const CustomCloseIconButton = styled(IconButton)({
  transition: 'transform 0.3s ease',
  color: '#fff',
})
const CustomOpenIconButton = styled(IconButton)({
  color: '#053348',
  textAlign : "end"
})
const CustomHeaderTableCell = styled(TableCell)({
  fontWeight: 'bold', minWidth: '150px',
  color : "#053348"
})
const CustomButton = styled('button')({
  background:"none",
  border: "none",
  padding: "10px",
  color : "#00496B",
  fontSize : "16px",
  fontWeight : "700",
  fontFamily : "Roboto"
})
const webStyles = {
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    gap: "2rem"
  },
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem"
  },
  candidatesFontSize: {
    fontSize: "1rem"
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem"
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "grey",
    fontWeight: "lighter",
    fontSize: "3rem"
  },
  typeText: { fontSize: "0.8rem", textTransform: "capitalize" }
};
// Customizable Area End
