// Customizable Area Start
import React from "react";
import OTPInputAuthController, { 
    Props
} from "../../otp-input-confirmation/src/OTPInputAuthController";
// Customizable Area End
export default class OTPInputAuth extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <></>
    );
    // Customizable Area End
  }

}

// Customizable Area Start
// Customizable Area End
