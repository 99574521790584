import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  styled,
  Box
} from "@mui/material";
import { warningIcon } from './assets';
import { useTranslation } from 'react-i18next';


interface CustomLogoutDialogProps {
  isDialogOpen: boolean; 
  handleCloseDialog: () => void; 
  handleConfirmLogout: () => void;
  title:string,
  content : string,
  confrimButton :  string,
}

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    maxWidth: "500px", 
    width: "100%",
    height: "60vh", 
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const SyledImageContainer = styled(Box)({
  background: "#FEE2E2",
  borderRadius : "50%",
  width: "fit-content",
  marginLeft:"40%"
  
})
const StyledImage = styled('img')(({ theme }) => ({
  height: '20px',
  width: "20px",
  padding:"10px",
}));

const StyledDialogTitle = styled(DialogTitle)(() => ({
  fontSize: "22px",
  fontWeight: "bold",
  color: "#0F172A", 
  textAlign: "center",
  position: "relative",
  top:"20px",
  marginBottom : "10px"
}));

const StyledDialogContent = styled(DialogContent)(() => ({
  textAlign: "center", 
}));

const StyledDialogContentText = styled(DialogContentText)(() => ({
  fontSize: "16px",
  color: "#0F172A",
  display: "flex",
  flexDirection: "column",
}));

const StyledDialogActions = styled(DialogActions)(() => ({
  justifyContent: "flex-end",
  padding:"20px",
  borderTop:"1px solid #E2E8F0",
  width:"91%"
}));

const StyledButton = styled(Button)(() => ({
  padding:"12px 14px",
  marginLeft: "10px",
  textTransform: "none",
  "&.cancel" :{
    color:"#00496B",
    backgroundColor: "FFFFFF"
  },
  "&.confrim" :{
    color:"#FFFFFF",
    backgroundColor: "#00496B",
  },
  borderRadius:"8px"
}));

export default function CustomLogoutDialog({
  isDialogOpen,
  handleCloseDialog,
  handleConfirmLogout,
  title,
  content,
  confrimButton
}: CustomLogoutDialogProps) {
  const { t } = useTranslation();
  return (
    <StyledDialog open={isDialogOpen} onClose={handleCloseDialog}>
      
      <StyledDialogContent>
        <StyledDialogContentText>
          <SyledImageContainer>
          <StyledImage src = {warningIcon.default} alt = "warning"/>
          </SyledImageContainer>
        <StyledDialogTitle>{t(title)}</StyledDialogTitle>
          {t(content)}
        </StyledDialogContentText>
      </StyledDialogContent>
      <StyledDialogActions>
        { confrimButton === "Log Out" &&
        <StyledButton onClick={handleCloseDialog}  className="cancel">
          {t("Cancel")}
        </StyledButton>}
        <StyledButton onClick={handleConfirmLogout} color="primary" className="confrim">
          {t(confrimButton)}
        </StyledButton>
      </StyledDialogActions>
    </StyledDialog>
  );
}