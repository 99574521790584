import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import MessageEnum, {
    getName,
  } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";  
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  otpAuthToken: string;
  labelInfo: string;
  remainingTime: number;
  isRequestEnabled: boolean;
  OTPWebLabel: string;
  OTPWebSubLabel: string;
  otpValues:string[];
  isButtonEnabled:boolean;
  alertMessage : string;
  isErrorAlert : boolean;
  isSuccessAlert: boolean;
  password: string;
  mobileNumber : string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpAuthApiCallId: any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  labelInfo: string = "";
  submitButtonColor: any = configJSON.submitButtonColor;
  timerInterval: NodeJS.Timeout | null = null;
  activateApiCallId: any;
  resendApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    const token = localStorage.getItem("token") || "";
    this.state = {
        otp: "",
        otpAuthToken: token,
        labelInfo: configJSON.labelInfo,
        OTPWebSubLabel: configJSON.OTPWebSubLabel,
        OTPWebLabel: configJSON.OTPWebLabel,
        otpValues: ["","","","","",""],
        isButtonEnabled:false,
        remainingTime: 119,
        isRequestEnabled: false,
        alertMessage: configJSON.OTPSentText,
        isSuccessAlert:false,
        isErrorAlert:false,
        password: "",
        mobileNumber: "",
      };
  
      this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
      this.placeHolderOtp = configJSON.placeHolderOtp;
      this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
      ];
  
      this.receive = this.receive.bind(this);
  
      runEngine.attachBuildingBlock(this, this.subScribedMessages);
  
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const responseId = getName(MessageEnum.RestAPIResponceMessage);
    const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (responseId !== message.id) return;

    if (this.otpAuthApiCallId != null && this.otpAuthApiCallId === apiCallId) {
        this.handleOtpResponse(responseJson);
    } 
     else if (this.resendApiCallId != null && this.resendApiCallId === apiCallId) {
        this.handleResendResponse(responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleOtpResponse(responseJson: any) {
    if (responseJson?.message !== "OTP verified successfully") {
        this.setErrorState(responseJson.message);
        return;
    }
    const responseOTP = responseJson.meta;
    const user = {
      email: responseOTP.account.email,
      activate_account: responseOTP.account.activate_account,
      phone_number: responseOTP.account.phone_number,
      language: responseOTP.account.language,
      internal_code: responseOTP.account.internal_code,
      first_name: responseOTP.account.first_name,
      token: responseOTP.token,
  };
  this.handlerRedirection();
  localStorage.setItem("token" ,JSON.stringify(responseOTP.token));
  localStorage.setItem("user", JSON.stringify(user));
  }
  handlerRedirection () {
    const otpStatus = localStorage.getItem("otpStatus");
    console.log(otpStatus)
    if(otpStatus !== "reset"){
      window.location.href = "/Dashboard";
      return;
    }
    localStorage.removeItem("otpStatus");
    localStorage.setItem("otp",this.state.otp)
    window.location.href = "/CreateNewPassword";
  }
  handleResendResponse(responseJson: any) {
    if (responseJson?.error) {
        this.setErrorState(responseJson.error);
        return;
    }
    if (responseJson.message) {
        this.setState({ isSuccessAlert: true, alertMessage: configJSON.newOTPTeaxt, isErrorAlert: false });
    }
  }

  setErrorState(errorMessage: string) {
    this.setState({ isErrorAlert: true, alertMessage: errorMessage, isSuccessAlert: false });
  }
  async componentDidMount() {
    this.getPhoneNumber();
    this.startTimer();
    this.getPasswordFromURL();
    window.history.pushState(null ,"",window.location.href);
    window.addEventListener('popstate',this.handlePopState);
    return Promise.resolve();
  }
  handlePopState = () => {
    window.history.pushState(null ,"",window.location.href);
  }

  async componentWillUnmount() {
    window.removeEventListener('popstate',this.handlePopState);
    this.clearTimer();
  }
  getPhoneNumber = () => {
    const phoneNumber = localStorage.getItem("phoneNumber") || "";
    const lastFourDigits = phoneNumber.slice(-4);
    this.setState({ mobileNumber: lastFourDigits });
  }
  startTimer = () => {
    this.timerInterval = setInterval(() => {
      const { remainingTime } = this.state;
      if (remainingTime > 0) {
        this.setState({ remainingTime: remainingTime - 1 });
      } else {
        this.clearTimer();
        this.setState({ isRequestEnabled: true,isButtonEnabled:false }); 
      }
    }, 1000);
  };

  clearTimer = () => {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
      this.timerInterval = null;
    }
  };

  formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };
  handleBackspace(index: number, event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Backspace") {
      const otpValues = [...this.state.otpValues];
      if (otpValues[index] === "") {
        if (index > 0) {
          otpValues[index - 1] = "";
          this.setState({
            otpValues,
          }, () => {
            (document.getElementById(`otp-input-${index - 1}`) as HTMLInputElement)?.focus();
          });
        }
      } else {
        otpValues[index] = "";
        this.setState({
          otpValues,
        });
      }
      this.setState({
        isButtonEnabled: false
      })
    }
  }

  handleOTPChange(index: number, event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    if (/^\d*$/.test(value) && value.length <= 1) {
      const otpValues = [...this.state.otpValues];
      otpValues[index] = value;
      this.setState({
        otpValues,
        isButtonEnabled: otpValues.every((digit) => digit !== ""),
        otp: otpValues.join("")
      });
      if (value && index < 5) {
        (document.getElementById(`otp-input-${index + 1}`) as HTMLInputElement)?.focus();
      }
    }
  }
  handleRequestNewOTP = () => {
    this.setState({ remainingTime: 119, isRequestEnabled: false, isButtonEnabled:true}, () => {
        this.startTimer();
      });
      this.reSendOtp();
  }

  handleCloseAlert = () => {
    this.setState({
        alertMessage: "",
        isErrorAlert:false,
        isSuccessAlert : false,
    })
  }
  getPasswordFromURL() {
    const urlParams = new URLSearchParams(window.location.search);
    const password = urlParams.get("password");
    if (password) {
      this.setState({ password });
      return password
    }
  }
resetAlertState = () => {
    this.setState({
      isSuccessAlert: false,
      isErrorAlert: false,
    });
  };
  
  createRequestHeader = (contentType: string, token?: string) => {
    const header: any = {
      "Content-Type": contentType,
    };
    if (token) {
      header.token = token;
    }
    return header;
  };
  
  createRequestMessage = (
    endpoint: string,
    method: string,
    header: any,
    body: any
  ) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
  
    return requestMessage;
  };
  
  sendRequest = (
    endpoint: string,
    method: string,
    contentType: string,
    apiCallIdField: string,
    bodyData: any,
  ) => {
    this.resetAlertState();
  
    const header = this.createRequestHeader(contentType);
  
    const requestMessage = this.createRequestMessage(
      endpoint,
      method,
      header,
      bodyData
    );
  
    (this as any)[apiCallIdField] = requestMessage.messageId;
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  handleOTPsubmit = () => {
    this.sendRequest(
      configJSON.apiWebOtpEndPoint,
      configJSON.apiVerifyOtpMethod,
      configJSON.apiVerifyOtpContentType,
      "otpAuthApiCallId",
      { otp: this.state.otp,
        email: localStorage.getItem("email"),
        password: this.state.password || ""
       },
    );
  };
  reSendOtp = () => {
    this.sendRequest(
      configJSON.resendOTPApiEndPoint,
      configJSON.webActivateMethod,
      configJSON.apiVerifyOtpContentType,
      "resendApiCallId",
      {
        email: localStorage.getItem("email") || "",
      }
    );
  };
  
  // Customizable Area End
}
