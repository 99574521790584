import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  dashboardDepartments: any;
  selectedDepartment: string;
  singleDepartmentDeviceUser: any;
  SingleDeviceDataList : any;
  selectedDepartmentUser: string;
  expandedDevice: boolean;
  expandedARC: boolean;
  expandedNotification: boolean;
  expanded: any;
  currentId: number;
  isPreviousButton : boolean;
  isNextButton : boolean;
  currentIndex : number;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiSingleDashboardItemCallId: any = "";
  apiSingleDeviceCallId: any = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      dashboardDepartments: [],
      selectedDepartment: "",
      singleDepartmentDeviceUser: [],
      SingleDeviceDataList: [],
      selectedDepartmentUser: "",
      expandedDevice: false,
      expandedARC: false,
      expandedNotification : false,
      expanded: [{
        expanded: false,
        key: "device"
      },{
        expanded: false,
        key: "ARC"
      },
      {
        expanded : false,
        key:"notification"
      }
    ],
     currentId: 0,
     isNextButton: false,
     isPreviousButton: false,
     currentIndex : 0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    window.history.pushState(null ,"",window.location.href);
    window.addEventListener('popstate',this.handlePopState);
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    this.setState({
      selectedDepartment :"",
      selectedDepartmentUser : "",
      singleDepartmentDeviceUser : [],
      SingleDeviceDataList : []
    })
    const webHeader = {
      "Content-Type": webConfigJSON.dashboardContentType,
      token: this.getToken()
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.webDashboardDepartmentsApiEndPoint
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const webResponseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (webApiRequestCallId === this.apiDashboardItemCallId) {
        await this.handleDashboardItemResponse(webResponseJson);
      }
      else if (webApiRequestCallId === this.apiSingleDashboardItemCallId) {
        await this.handleSingleDashboardItemResponse(webResponseJson);
      }
      else if (webApiRequestCallId === this.apiSingleDeviceCallId) {
        await this.handleSingleDeviceResponse(webResponseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleUpdateTheButtonStatus = (currentId: number) => {
    const departmentCount = this.state.singleDepartmentDeviceUser.length || 0
    if (departmentCount <= 1) {
      this.setState({
        isPreviousButton: false,
        isNextButton: false
      });
      return;
    }
    this.setState({
      isPreviousButton: currentId > 1,
      isNextButton: currentId < departmentCount
    });
  };
  
  handlePreviousClick = () => {

    const reduceOne = this.state.currentId - 1;
    const reduceOneIndex = this.state.currentIndex - 1;
    this.handleSingleDevice(reduceOne ,reduceOneIndex, this.state.selectedDepartmentUser)();
    this.setState({currentId : reduceOne});
    this.handleUpdateTheButtonStatus(reduceOneIndex);
  }
  handleNextClick = () => {
    const updateOne = this.state.currentId + 1;
    const updateOneIndex = this.state.currentIndex + 1;
    this.handleSingleDevice(updateOne ,updateOneIndex, this.state.selectedDepartmentUser)();
    this.setState({currentId : updateOne});
    this.handleUpdateTheButtonStatus(updateOneIndex)
  }
  async componentWillUnmount() {
    window.removeEventListener('popstate',this.handlePopState);
  }
  handlePopState = () => {
    window.history.pushState(null ,"",window.location.href);
  }
  getToken () {
    return localStorage.getItem('token')?.replace(/^"(.*)"$/, '$1') || "";
  }
  async handleDashboardItemResponse(webResponseJson :any) {
    if (webResponseJson && !webResponseJson.errors) {
      if (webResponseJson.departments.length === 1) {
        this.handleDepartmentClick(webResponseJson.departments[0].department);
      }
      this.setState({
        dashboardDepartments: webResponseJson.departments,
        errorMsg: "",
        loading: false,
      });
      return;
    } 
    this.setState({
      errorMsg: webResponseJson.errors.token,
      loading: false,
    });
  }
  
  async handleSingleDashboardItemResponse(webResponseJson :any) {  
    if (webResponseJson && !webResponseJson.errors) {
      this.setState({
        singleDepartmentDeviceUser: webResponseJson.device_users
      });
      return;
    }
    this.setState({
      errorMsg: webResponseJson.errors.token,
      loading: false,
    });
  }
  
  async handleSingleDeviceResponse(webResponseJson :any) {
    if (webResponseJson && !webResponseJson.errors) {
      const response = this.removeNulls(webResponseJson)
      this.setState({
        SingleDeviceDataList: response.device_users,
        selectedDepartmentUser : response.device_users[0].device_id
      });
      return;
    }
    this.setState({
      errorMsg: webResponseJson.errors.token,
      loading: false,
    });
  }
  handleDepartmentClick = (department: string) => { 
    this.setState({
      selectedDepartment: department,
      selectedDepartmentUser : ""

    })
    this.handleSingleDepartment(department)
  }
  handleSingleDepartment = (department: string) => {
    
    const webHeader = {
      "Content-Type": webConfigJSON.dashboardContentType,
      token: this.getToken()
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSingleDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.webDashboardDeviceDetailsApiEndPoint+`?department=${department}`
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);

    return true;
  }
  handleSingleDevice = (id: number , index: number, selectedDepartmentUser: string) => () => {
    this.setState({
      selectedDepartmentUser,
      currentId : id,
      currentIndex : index,
    });
    this.handleUpdateTheButtonStatus(index)
    const webHeader = {
      "Content-Type": webConfigJSON.dashboardContentType,
      token: this.getToken()
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSingleDeviceCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.webSingleDeviceDetailsApiEndPoint+`?id=${id}`
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }
  removeNulls = (data: any): any => {
    if (Array.isArray(data)) {
        return data
            .map(item => this.removeNulls(item)) 
            .map(item => item === null ? "" : item);
    } else if (typeof data === 'object' && data !== null) {
        return Object.entries(data).reduce((acc, [key, value]) => {
            const cleanedValue = this.removeNulls(value);
            acc[key] = cleanedValue === null ? "" : cleanedValue;
            return acc;
        }, {} as any);
    }
    return data;
  }


  // Customizable Area End
}
