// Customizable Area Start
import React from "react";
import { Box, Typography, styled} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"
import i18n from "../../../components/src/i18nextConfig/i18n";
import OTPInputAuthController, { 
    Props
} from "./OTPInputAuthController.web";

// Customizable Area End
export default class OTPInputAuth extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    const {t} =  i18n; 
    const { remainingTime, isRequestEnabled } = this.state;
    return (
      
      <>
        <Main>
          <Body>
          <HeaderContainer>
            <Header variant="h4" >
                { t(this.state.OTPWebLabel)}
            </Header>
            <SubHeader variant="h1">
              {t(this.state.OTPWebSubLabel )+ " "+ this.state.mobileNumber }
            </SubHeader>
            { this.state.isSuccessAlert && !this.state.isErrorAlert && <ShowMessageAlert>
              <MessageText>{ t ( this.state.alertMessage)}</MessageText>
              <CloseIcon onClick={this.handleCloseAlert}/>
            </ShowMessageAlert>}
            { this.state.isErrorAlert && !this.state.isSuccessAlert && <ShowMessageAlert className="error-message">
              <MessageText>{ 
                t(this.state.alertMessage)
              }</MessageText>
              <CloseIcon onClick={this.handleCloseAlert}/>
            </ShowMessageAlert>}
          </HeaderContainer>
          <OTPContainer>
              {this.state.otpValues.map((value, index) => (
                <OTPInput
                  key={index}
                  id={`otp-input-${index}`}
                  type="text"
                  value={value}
                  onChange={(e) => this.handleOTPChange(index, e)}  // Handle digit input
                  onKeyDown={(e) => this.handleBackspace(index, e)} // Handle backspace key press
                  autoFocus={index === 0 && value === ""}
                  maxLength={1}
                />
              ))}
            </OTPContainer>
            <BottomContainer>
            {!isRequestEnabled && <TimeText>{this.formatTime(remainingTime)}</TimeText>}
              <RequestText
                onClick={isRequestEnabled ? this.handleRequestNewOTP : undefined}
                className={isRequestEnabled ? "enabled" : "disabled"}
              >
                {t ( "Request a new code") }
              </RequestText>
            <VerifyButton
            className={this.state.isButtonEnabled ? "enabled" : "disabled"}
            disabled={!this.state.isButtonEnabled}
            onClick={this.handleOTPsubmit}
            >{t ("Verify")} </VerifyButton>
            </BottomContainer>

          </Body>
        </Main>
      </>
      
    );
    // Customizable Area End
  }

}
// Customizable Area Start
const Main = styled(Box)({
  padding: "20px",
  display: "flex",
  alignItems: "center",
  background: "#E9F6FF",
  justifyContent: "center",
  height:"calc(100vh - 130px)",
  maxWidth:"100%",
});
const Body = styled(Box)({
  background: "#FFFFFF",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "30px 10px",
  borderRadius: "10px",
  width: "100%",
  maxWidth: "350px",
  justifyContent: "space-between",
  height:"400px",
});
const HeaderContainer = styled("div")({
  textAlign:"center"
})
const Header = styled(Typography)({
  fontFamily: "Roboto",
  fontSize: "24px",
  marginBottom: "15px",
  color: "#053348",
  fontWeight: 800,
});

const SubHeader = styled(Typography)({
  fontFamily: "Roboto",
  fontSize: "16px",
  fontWeight: 500,
  color: "#053348",
});
const ShowMessageAlert = styled(Box)({
  background: "#ECFDF5",
  borderLeft: "4px solid #059669",
  width: "Fill (405px)px", 
  height: "Hug (48px)px",
  padding: "12px 8px 12px 16px",
  display: "flex", 
  alignItems: "center", 
  justifyContent: "space-between", 
  borderRadius: "4px 0px 0px 0px",
  color: "#0F172A",
  opacity: 1,
  "&.error-message":{
    background:"#FEE2E2",
    borderLeft: "4px solid #E4022E",
    color: "#E4022E"
  }
});

const MessageText = styled(Typography)({
  fontFamily: "Roboto",
  fontSize: "12px",
  fontWeight: 400,
  textAlign: "left",
});

const OTPContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
  marginBottom: "20px",
});

const OTPInput = styled("input")({
  width: "40px",
  height: "40px",
  margin: "0 5px",
  textAlign: "center",
  fontSize: "18px",
  borderRadius: "5px",
  border: "1px solid #CBD5E1",
  outline: "none",
  backgroundColor: "#f3f3f3",
});
const BottomContainer = styled("div")({
  textAlign:"center",
  width:"100%"
})
const TimeText = styled(Typography)({
  color:'#0F172A',
  fontSize:"16px"
})
const RequestText = styled(Typography)({
  cursor: 'not-allowed',
  margin: "20px 0px",
  fontSize: "14px",
  color:"#94A3B8",
  "&.enabled":{
    color : "#00496B",
    cursor : "pointer"
  }
});
const VerifyButton = styled("button")({
  background: "#A0AEC0", 
  border: "none",
  color: "#ffffff",
  fontWeight: "700",
  width: "90%",
  padding: "14px 80px",
  fontSize: "14px",
  borderRadius: "5px",
  cursor: "not-allowed",
  opacity: 0.6,
  "&.enabled": {
    background: "#00496B",
    cursor: "pointer",
    opacity: 1,
  }
});
// Customizable Area End
