// Customizable Area Start
import React from "react";
import { Input, Button } from '@builder/component-library';
import { Box, Typography, styled, Checkbox } from "@mui/material";
import VisibilityOffIcons from '@mui/icons-material/VisibilityOff';
import VisibilityIcons from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController.web";
import i18n from "../../../components/src/i18nextConfig/i18n";
import { secureLogo } from './assets'
// Customizable Area End
export default class EmailAccountRegistrationBlock extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  openTermsModal = () => {
    this.setState({ showTerms: true });
  };
  renderTermsAndCondition = () => {
    return (
      <CardContainer>
        <CardHeader>
          <Typography variant="h5" style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
            <Logo src={secureLogo.default} style={{ marginRight: 8 }} />
            {i18n.t("Terms of Service")}
          </Typography>

          <CloseButton onClick={() => this.setState({ showTerms: false })}>
            <CloseIcon />
          </CloseButton>
        </CardHeader>
        <CardContent>
          <Typography variant="body2">
            {this.state.termsDescription}
          </Typography>
        </CardContent>
        <ButtonsWrapper>
          <Button
            data-test-id="btnCancel"
            text={i18n.t("Cancel")}
            onPress={() => this.setState({ showTerms: false })}
            style={{
              backgroundColor: "transparent",
              marginBottom: "8px",
              marginTop: "20px",
              padding: 0,
            }}
            textStyle={{
              color: "#00496B",
              fontSize: 14,
            }}
          />
          <Button
            data-test-id="btnAgreeContinue"
            style={{ backgroundColor: "#00496B", marginBottom: "10px" }}
            text={i18n.t("Agree & Continue")}
            onPress={() => this.setState({ checkedTermsAndConditions: true, showTerms: false })}
          />
        </ButtonsWrapper>
      </CardContainer>
    );
  };


  renderActivatePage = () => {
    const { t } = i18n;
    return (
      <FormWrapper>
        <HeadContainer>
          <Header variant="h4">{t("Activate Your Account")}</Header>
          <SubHeader variant="h1">{t(this.activationHeader)}</SubHeader>
        </HeadContainer>
        {this.state.errorStatus && (
          <ShowMessageAlert>
            <MessageText>{t(this.state.errorMessage)}</MessageText>
            <CloseIcon onClick={this.handleCloseErrorMessage} />
          </ShowMessageAlert>
        )}
        <InputWrapper>
          <Label>{t(this.emailLabel)}</Label>
          <CustomInput
            data-test-id="txtInputEmail"
            placeholder={"Email"}
            value={this.state.email}
            disabled
          />
        </InputWrapper>
        <InputWrapper>
          <Label>{t(this.passwordLabel)}</Label>
          <Input
            data-test-id="txtInputPassword"
            secureTextEntry={this.state.enablePasswordField}
            placeholder={this.passwordPlaceHolder}
            value={this.state.password}
            onChangeText={(text) => this.setPassword(text)}
            rightIcon={
              <Button
                style={{ backgroundColor: "transparent" }}
                data-test-id="btnPasswordShowHide"
                aria-label="toggle password visibility"
                onPress={this.handleClickShowPassword}
                icon={
                  this.state.enablePasswordField ? (
                    <VisibilityIcons style={{ color: "#94A3B8" }} />
                  ) : (
                    <VisibilityOffIcons style={{ color: "#94A3B8" }} />
                  )
                }
              />
            }
          />
        </InputWrapper>

        <CustomCheckbox>
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={this.state.checkedLetter}
              inputProps={{ 'aria-label': 'controlled' }}
              icon={
                this.state.password.length > 0 && !this.state.checkedLetter ? (
                  <RemoveIcon sx={{ color: '#ffffff' }} />
                ) : undefined
              }
              checkedIcon={
                <CheckIcon sx={{ color: '#ffffff', backgroundColor: '#059669', borderRadius: '4px' }} />
              }
              sx={
                this.state.password.length > 0 && !this.state.checkedLetter
                  ? redMinusStyle
                  : {}
              }
            />
            <CheckBoxText>{t(this.passwordCondition1)}</CheckBoxText>
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              data-test-id={"btnRememberMe"}
              style={{ margin: 1 }}
              checked={this.state.checkedLength}
              icon={
                this.state.password.length > 0 && !this.state.checkedLength ? (
                  <RemoveIcon sx={{ color: '#ffffff' }} />
                ) : undefined
              }
              checkedIcon={
                <CheckIcon sx={{ color: '#ffffff', backgroundColor: '#059669', borderRadius: '4px' }} />
              }
              sx={
                this.state.password.length > 0 && !this.state.checkedLength
                  ? redMinusStyle
                  : {}
              }
            />
            <CheckBoxText>{t(this.passwordCondition2)}</CheckBoxText>
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              data-test-id={"btnRememberMe"}
              style={{ margin: 1 }}
              checked={this.state.password.length > 0 && this.state.checkedDigit}
              icon={
                this.state.password.length > 0 && !this.state.checkedDigit ? (
                  <RemoveIcon sx={{ color: '#ffffff' }} />
                ) : undefined
              }
              checkedIcon={
                <CheckIcon sx={{ color: '#ffffff', backgroundColor: '#059669', borderRadius: '4px' }} />
              }
              sx={
                this.state.password.length > 0 && !this.state.checkedDigit
                  ? redMinusStyle
                  : {}
              }
            />
            <CheckBoxText>{t(this.passwordCondition3)}</CheckBoxText>
          </Box>
        </CustomCheckbox>
        <Box display="flex" alignItems="center">
          <Checkbox
            data-test-id={"btnRememberMe"}
            title=""
            style={{ margin: 1 }}
            onChange={this.handleCheckbox}
            checked={this.state.checkedTermsAndConditions}
          />
          <CheckBoxText sx={{ color: "#475569", fontSize: "14px" }}>
            {t(this.agreeWith)}{" "}
            <StyledLink onClick={this.openTermsModal}>
              {t(this.termsAndConditions)}
            </StyledLink>
          </CheckBoxText>
        </Box>
        <ButtonsWrapper>
          <Button
            data-test-id={"btnEmailLogIn"}
            style={{ backgroundColor: "#00496B" }}
            text={t(this.btnText)}
            onPress={() => this.createAccount()}
          />
        </ButtonsWrapper>
      </FormWrapper>
    );
  };

  // Customizable Area End
  render() {
    // Customizable Area Start

    return (

      <>
        <Main>
          <Body>
            {this.state.showTerms
              ? this.renderTermsAndCondition()
              : this.renderActivatePage()
            }
          </Body>
        </Main>
      </>

    );
    // Customizable Area Start
  }

}
// Customizable Area Start
const Main = styled(Box)({
  display: "flex",
  alignItems: "center",
  background: "#E9F6FF",
  justifyContent: "center",
  height:"calc(100vh - 120px)",
  fontFamily: "Roboto",
  color: "#053348",
  padding: "20px"
});

const Body = styled(Box)({
  background: "#FFFFFF",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "10px",
  borderRadius: "10px",
  width: "90%",
  maxWidth: "460px",
});
const Logo = styled('img')(({ theme }) => ({
  height: '57px',
  width: "57px",
}));
const HeadContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "5px",
});

const Header = styled(Typography)({
  fontSize: "24px",
  fontWeight: 600,
});

const SubHeader = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  marginBottom: "20px"
});

const ShowMessageAlert = styled(Box)({
  margin: "10px 10px",
  background: "#FEE2E2",
  borderLeft: "4px solid #E4022E",
  padding: "12px 30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "4px",
  color: "#E4022E",
  opacity: 1,
  width: "81%",
});

const MessageText = styled(Typography)({
  fontSize: "12px",
  fontWeight: 400,
  textAlign: "left",
});

const FormWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  padding: "0 30px",
});

const InputWrapper = styled(Box)({
  paddingBottom: "5px",
});

const CustomInput = styled('input')({
  border: "solid 1px #CBD5E1",
  backgroundColor: "#ededed",
  color: "#94A3B8",
  cursor: "not-allowed",
  width: "100%",
  padding:"12px 10px",
  borderRadius: "8px"
});
const CustomCheckbox = styled(Box)({
  display:"flex",
  flexDirection:"column",
});

const redMinusStyle = {
  '& .MuiSvgIcon-root': {
    backgroundColor: '#E4022E',
    borderRadius: '4px',
  },
};

const CheckBoxText = styled(Typography)({
  fontSize: "12px",
  width:"100%",
});

const StyledLink = styled('a')(({ theme }) => ({
  color: '#00496B',
  textDecoration: 'underline',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'none',
  },
}));

const Label = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  marginBottom: "4px",
});

const ButtonsWrapper = styled(Box)({
  flex: 1,
  padding: "8px",
  marginBottom: "10px"
});

const CardContainer = styled(Box)(() => ({
  padding: "20px",
  borderRadius: "10px",
  width: "90%",
  maxWidth: "460px",
  position: "relative",
  margin: "20px auto",
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "10px",
}));

const CloseButton = styled(Box)(() => ({
  cursor: "pointer",
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const CardContent = styled(Box)(() => ({
  maxHeight: "400px",
  overflowY: "auto",
  fontSize: "14px",
  lineHeight: "1.6",
}));
// Customizable Area End

