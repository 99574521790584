import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";


// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  email: string;
  password: string;
  otpAuthToken: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  checkedTermsAndConditions: boolean;
  checkedLetter: boolean;
  checkedDigit: boolean;
  checkedLength: boolean;
  errorMessage: string;
  errorStatus : boolean;
  internalCode : string;
  termsDescription: string;
  termsLanguage: string;
  showTerms: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAccountApiCallId: any;
  activationTitle:string;
  activationHeader:string;
  emailLabel :string;
  passwordLabel :string;
  passwordPlaceHolder: string;
  passwordCondition1:string;
  passwordCondition2:string;
  passwordCondition3:string;
  btnText: string;
  agreeWith:string;
  termsAndConditions: string;
  validationApiCallId: string = "";
  apinotificationCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    //Customizable Area Start
      this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.NavigationPayLoadMessage),
        getName(MessageEnum.CountryCodeMessage)
      ];
      this.receive = this.receive.bind(this);
      runEngine.attachBuildingBlock(this, this.subScribedMessages);
      this.state = {
        email: "",
        password: "",
        otpAuthToken: "",
        data: [],
        passwordHelperText: "",
        enablePasswordField: true,
        checkedTermsAndConditions:false,
        checkedLength:false,
        checkedLetter:false,
        checkedDigit: false,
        errorMessage: "",
        errorStatus: false,
        internalCode: "",
        termsDescription: "",
        termsLanguage: "",
        showTerms: false,
      };
      this.activationTitle = configJSON.activationTitle;
      this.activationHeader = configJSON.activationHeader;
      this.emailLabel = configJSON.emailLabel;
      this.passwordLabel = configJSON.newPass + configJSON.word;
      this.passwordPlaceHolder = configJSON.pass + configJSON.passwordPlaceHolder;
  
      this.btnText = configJSON.btnText;
      this.agreeWith = configJSON.agreeWith;
      this.termsAndConditions = configJSON.termsAndConditions;
      this.passwordCondition1 = configJSON.pass + configJSON.passwordCondition1;
      this.passwordCondition2 = configJSON.pass + configJSON.passwordCondition2;
      this.passwordCondition3 =configJSON.pass + configJSON.passwordCondition3;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
        return;
      }
    
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
      if (!apiRequestCallId || !responseJson) {
        return;
      }
    
      if (apiRequestCallId === this.createAccountApiCallId) {
        this.handleCreateAccountResponse(responseJson);
      }
      if (apiRequestCallId === this.apinotificationCallId) {
        this.handletermsAndConditions(responseJson);
      }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email") || "";
    const phoneNumber = urlParams.get("phone_number") || "";
    const internalCode = urlParams.get("internal_code") || "";
    const urlLanguage = (urlParams.get('language') || 'english') ; 
    const language = this.chooseCorrectLanguage(urlLanguage,email)
    localStorage.setItem("language",language);
    localStorage.setItem("email",email)
    localStorage.setItem("phoneNumber",phoneNumber);
    this.setState({email:email , internalCode});
    window.history.pushState(null ,"",window.location.href);
    window.addEventListener('popstate',this.handlePopState);
    this.handleNotification();
  }
  chooseCorrectLanguage = (urlLanguage : string,urlEmail : string): string => {
    const storedEmail = localStorage.getItem("email") || "";
    const storedLanguage = localStorage.getItem('language') || 'en';
    const languageMap: Record<string, string> = {
      german: 'de',
      english: 'en',
      dutch: 'nl'
    };
    const languageCode = languageMap[urlLanguage.toLowerCase()] || 'en';
    if (!storedEmail || urlEmail !== storedEmail) {
      return languageCode;
    }
    return storedLanguage;
  };
  
  async componentWillUnmount() {
    window.removeEventListener('popstate',this.handlePopState);
  }
  handlePopState = () => {
    window.history.pushState(null ,"",window.location.href);
  }
  validatePasswordConditions = (password: string) => {
    const hasLetter = /[a-zA-Z]/.test(password); 
    const hasMinimumLength = password.length >= 8; 
    const hasDigit = /\d/.test(password); 
  
    this.setState({
      checkedLetter: hasLetter,
      checkedLength: hasMinimumLength,
      checkedDigit: hasDigit,
    });
  };
  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
    this.validatePasswordConditions(text);
  };
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
  handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({checkedTermsAndConditions:event.target.checked});
  };
  createAccount(): boolean {
    if (
      !this.state.email ||
      !this.state.password ||
      !this.state.checkedLetter ||
      !this.state.checkedLength ||
      !this.state.checkedDigit ||
      !this.state.checkedTermsAndConditions
    ) {
        this.setState({
            errorStatus:!this.state.checkedTermsAndConditions,
            errorMessage:configJSON.errorMessage
        })
      return false;
    }
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };
    this.setState({
        errorStatus:false,
        errorMessage:''
    })
    const data = {
      email: this.state.email,
      internal_code : this.state.internalCode
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.webApiMethodTypeActivateAccount
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleCreateAccountResponse(responseJson: any) {
    if (responseJson.error) {
      this.setState({errorStatus:true,errorMessage:responseJson.error});
      return;
    }
    localStorage.setItem('email', this.state.email);
    window.location.href = `/OTPInputAuth?password=${this.state.password}`;
  }
  handletermsAndConditions(responseJson: any) {
    if (responseJson && responseJson.data && responseJson.data.attributes) {
      const { description, language } = responseJson.data.attributes;
      const parser = new DOMParser();
      const parsedDoc = parser.parseFromString(description, 'text/html');
      const descriptionContent = parsedDoc.body.textContent || ''; 
  
      this.setState({
        termsDescription: descriptionContent,
        termsLanguage: language,
      });
  }
  }

  handleCloseErrorMessage = () => {
    this.setState({ errorMessage: '' ,errorStatus:false});
  }
  handleNotification = () => {
    
    const languageOption = [{
      language : "english",
      short: "en"
    },{
      language : "dutch",
      short: "nl"
    },{
      language : "german",
      short : "de"
    }]
    const storedLanguage = localStorage.getItem('language');
    const selectedLanguage = languageOption.find(option => option.short === storedLanguage);
    const language = selectedLanguage ? selectedLanguage.language : "english";
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apinotificationCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationApiEndPoint+`?language=${language}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);

    return true;
  }
  // Customizable Area End
}
