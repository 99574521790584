// App.js - WEB
import React, { Component ,useRef,useEffect} from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import EmailAccountRegistrationBlock from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Cfrelatetemplatestosapuser from "../../blocks/cfrelatetemplatestosapuser/src/Cfrelatetemplatestosapuser";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Cftriggeraccountinvitefromsap from "../../blocks/cftriggeraccountinvitefromsap/src/Cftriggeraccountinvitefromsap";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Cfdashboarddatafetch0 from "../../blocks/cfdashboarddatafetch0/src/Cfdashboarddatafetch0";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Cfsubmitformtowebhook from "../../blocks/cfsubmitformtowebhook/src/Cfsubmitformtowebhook";
import Cfconfigureemailtemplateswithsmtpdetails from "../../blocks/cfconfigureemailtemplateswithsmtpdetails/src/Cfconfigureemailtemplateswithsmtpdetails";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Cfemailrichtexteditor from "../../blocks/cfemailrichtexteditor/src/Cfemailrichtexteditor";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Settings2 from "../../blocks/settings2/src/Settings2";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Cfgetnewaccountsfromsap from "../../blocks/cfgetnewaccountsfromsap/src/Cfgetnewaccountsfromsap";
import Cfautologout0 from "../../blocks/cfautologout0/src/Cfautologout0";
import Location from "../../blocks/location/src/Location";
import Analytics from "../../blocks/analytics/src/Analytics";
import LanguageOptions from "../../blocks/languageoptions/src/LanguageOptions";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import Cfaccountdeletionpushedfromsap0 from "../../blocks/cfaccountdeletionpushedfromsap0/src/Cfaccountdeletionpushedfromsap0";
import Cfaccountdatafetchfromsap0 from "../../blocks/cfaccountdatafetchfromsap0/src/Cfaccountdatafetchfromsap0";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Cfaccountremovalrequestfromsap from "../../blocks/cfaccountremovalrequestfromsap/src/Cfaccountremovalrequestfromsap";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import {AppHeader} from "../../components/src/AppHeader.web"
import CreateNewPassword from "../../blocks/forgot-password/src/NewPassword.web"
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import i18n from 'i18next';
import {SearchProvider} from "../../components/src/SearchContext"

const routeMap = {
Cfrelatetemplatestosapuser:{
 component:Cfrelatetemplatestosapuser,
path:"/Cfrelatetemplatestosapuser"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Cftriggeraccountinvitefromsap:{
 component:Cftriggeraccountinvitefromsap,
path:"/Cftriggeraccountinvitefromsap"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Cfdashboarddatafetch0:{
 component:Cfdashboarddatafetch0,
path:"/Cfdashboarddatafetch0"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Cfsubmitformtowebhook:{
 component:Cfsubmitformtowebhook,
path:"/Cfsubmitformtowebhook"},
Cfconfigureemailtemplateswithsmtpdetails:{
 component:Cfconfigureemailtemplateswithsmtpdetails,
path:"/Cfconfigureemailtemplateswithsmtpdetails"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Cfemailrichtexteditor:{
 component:Cfemailrichtexteditor,
path:"/Cfemailrichtexteditor"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Cfgetnewaccountsfromsap:{
 component:Cfgetnewaccountsfromsap,
path:"/Cfgetnewaccountsfromsap"},
Cfautologout0:{
 component:Cfautologout0,
path:"/Cfautologout0"},
Location:{
 component:Location,
path:"/Location"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
Cfaccountdeletionpushedfromsap0:{
 component:Cfaccountdeletionpushedfromsap0,
path:"/Cfaccountdeletionpushedfromsap0"},
Cfaccountdatafetchfromsap0:{
 component:Cfaccountdatafetchfromsap0,
path:"/Cfaccountdatafetchfromsap0"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Cfaccountremovalrequestfromsap:{
 component:Cfaccountremovalrequestfromsap,
path:"/Cfaccountremovalrequestfromsap"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
  ActivationPage: {
    component: EmailAccountRegistrationBlock,
    path: '/activate',
  },
  Dashboard : {
    component: Dashboard,
    path: '/Dashboard',
  },
  CreateNewPassword: {
    component :CreateNewPassword,
    path: "/CreateNewPassword"
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {

  const timerRef = useRef(null);

  const resetTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      handleLogout();
    }, 30 * 60 * 1000); 
  };

  const handleLogout = () => {
    const email = localStorage.getItem("email");
    localStorage.clear();
    window.location.href = `/EmailAccountLoginBlock?email=${email}&status=inActive`
  };
  const handleLanguageConversion = () => {
    const params = new URLSearchParams(window.location.search);
    const urlLanguage = params.get('language') || "";
    const languageMap = {
      german: 'de',
      english: 'en',
      dutch: 'nl'
    };  
    const languageCode = languageMap[urlLanguage.toLowerCase()] || 'en';
    let language =  localStorage.getItem("language") ||languageCode|| 'en';
    language = language.toLowerCase();
    localStorage.setItem("language",language);
    i18n.changeLanguage(language);
  }
  useEffect(() => {
    const token = localStorage.getItem("token");
    handleLanguageConversion()
    if (token) {
      resetTimer();
      const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
      events.forEach((event) => window.addEventListener(event, resetTimer));
      return () => {
        events.forEach((event) => window.removeEventListener(event, resetTimer));
        if (timerRef.current) clearTimeout(timerRef.current);
      };
    }
  }, []);
  const userNotLoggedInRoutes = [ "/emailaccountloginblock","/activate","/otpinputauth","/forgotpassword","/createnewpassword"];
  const userLoggedInRoutes = ["/createnewpassword","/dashboard"];
  function isUserLoggedIn(){
    const token = localStorage.getItem("token");
    if(token) return true;
    return  false;
  }
  function isOTPAvailble() {
    const otp = localStorage.getItem("otpStatus") || localStorage.getItem("otp");
    if(otp) return true;
    return false
  }
  let currentPath = window.location.pathname; 
  currentPath = currentPath.toLowerCase();
  if(!isUserLoggedIn()){
    if(!userNotLoggedInRoutes.includes(currentPath.toLowerCase()) || ( currentPath == "/createnewpassword") && !isOTPAvailble()){
      window.location.href = "/EmailAccountLoginBlock";
      return
    }
  }
  else{
    if(!userLoggedInRoutes.includes(currentPath.toLowerCase()) ||((currentPath.toLowerCase() == "/otpinputauth" || currentPath == "/createnewpassword") && !isOTPAvailble()) ){
      window.location.href = "/Dashboard";
      return
    }
  }
  return (
   <BuilderProvider>
      <View>
        <SearchProvider>
          <AppHeader/>
          <WebRoutesGenerator routeMap={routeMap} />
          <Outlet />
          <AlertBlock />
        </SearchProvider>
      </View>
    </BuilderProvider>
  );
}

export default App;