// Customizable Area Start
import React from "react";
import { Box, Typography, styled} from "@mui/material";

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController.web";
import i18n from "../../../components/src/i18nextConfig/i18n";

// Customizable Area End
export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  emailInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value as string
    this.setState({emailValue:email.trim()});
    this.isButtonEnabled = email.length > 0;
    this.setState({isError:false})
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {t} = i18n;
    return (
      <>
      <Main>
        <Body>
          <HeaderContainer>
            <HeadText data-test-id='headText'>{t(this.resetText)}</HeadText>
            <SubHeadText>{t(this.verifyText)}</SubHeadText>
            <ShowMessageAlert>
              <MessageText>{t(this.mailsendText)}</MessageText>
            </ShowMessageAlert>
          </HeaderContainer>
          <FormWrapper>
              <InputWrapper>
                <Label>{t(this.emailLabelText)}</Label>
                <CustomInput
                  data-test-id="txtInputEmail"
                  placeholder={t("Your "+this.emailLabelText)}
                  value={this.state.emailValue}
                  onChange={this.emailInputHandler}
                  className={this.state.isError? "invalid" : ""}
                />
                {this.state.isError && <AlertMessage>{t(this.state.errorMessage)}</AlertMessage>}
              </InputWrapper>
          </FormWrapper>
          <BottomContainer>
            <VerifyButton
            className={this.isButtonEnabled ? "enabled" : "disabled"}
            disabled={!this.isButtonEnabled}
            data-test-id="verifyButton"
            onClick={this.sendMailtoVerify}
            >{t("Send")}</VerifyButton>
            </BottomContainer>
        </Body>
      </Main>
      </>
      
    );
    // Customizable Area End
  }

}
// Customizable Area Start
const Main = styled(Box)({
  padding: "20px",
  display: "flex",
  alignItems: "center",
  background: "#E9F6FF",
  justifyContent: "center",
  fontFamily: "Roboto",
  maxWidth: "100%",
  height:"calc(100vh - 130px)",
});
const Body = styled(Box)({
  background: "#FFFFFF",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "30px 10px",
  borderRadius: "10px",
  width: "90%",
  maxWidth: "350px",
  height: "80%",
  maxHeight: "100%",
  justifyContent:"space-between"
});

const ShowMessageAlert = styled(Box)({
  background: "#ECFDF5",
  borderLeft: "4px solid #059669",
  width: "96%", 
  padding: "12px 7px 12px 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "4px",
  color: "#0F172A",
  opacity: 1,
});

const InputWrapper = styled(Box)({
  flex: 1,
  padding: "10px 20px",
  flexDirection: "column",
  marginBottom: "10px",
  fontSize: "14px",
});

const BottomContainer = styled("div")({
  textAlign: "center",
  width: "100%",
  marginTop: "20px",
});

const HeaderContainer = styled("div")({
  textAlign:"center",
  marginBottom:"20px",
  gap:"15px",
});
const HeadText = styled(Typography)({
  fontSize: "24px",
  marginBottom: "15px",
  color: "#053348",
  fontWeight: 800,
});
const  SubHeadText = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
  color: "#053348",
  padding:"10px 0px"
});


const MessageText = styled(Typography)({
  fontSize: "12px",
  fontWeight: 400,
  textAlign: "left",
});
const FormWrapper = styled(Box)({
  display: "flex",
  width: "100%",
  paddingTop: "20px",
  flexDirection: "column",
});
const Label = styled(Typography)({
  fontWeight: 500,
  color: "#053348",
  marginBottom: "5px",
});
const CustomInput = styled('input')(({ theme }) => ({
  padding: '18px 71px 18px 8px',
  border: "1px solid #CBD5E1",
  width: '100%',
  height: '56px',
  borderRadius: '8px',
  opacity: 1, 
  '&.invalid' : {
    border: "1px solid #E4022E",
  },
}));

const AlertMessage = styled(Typography)({
fontSize: "14px",
fontWeight: "400",
lineHeight: "16.8px",
textAlign: "left",
padding:"5px",
color:"#E4032E"

})

const VerifyButton = styled("button")({
  background: "#A0AEC0", 
  border: "none",
  color: "#ffffff",
  fontWeight: "700",
  width: "90%",
  padding: "14px 80px",
  fontSize: "14px",
  borderRadius: "5px",
  cursor: "not-allowed",
  opacity: 0.6,
  "&.enabled": {
    background: "#00496B",
    cursor: "pointer",
    opacity: 1,
  }
});
// Customizable Area End

