// Customizable Area Start
import React, { useEffect, useState } from 'react'
import {  Box, styled,Select,AppBar, Toolbar, Button,  SelectChangeEvent, MenuItem } from "@mui/material";
import { navLogo,English,german,dutch, logoutIcon } from './assets';
import CustomLogoutDialog from "./CustomLogoutDialog.web";
import SearchIcon from '@mui/icons-material/Search';
import i18n from './i18nextConfig/i18n';
// Customizable Area End
const languageOptions = [
  { value: 'en', label: 'English', image: English, alt: "en" },
  { value: 'de', label: 'German', image: german, alt: "de" },
  { value: 'nl', label: 'Dutch', image: dutch, alt: "nl" },
];

export function AppHeader() {
  const [language,setlanguage] = useState('en');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isLoggedIn,setIsLoggedIn] =  useState(false);

  const languageInputHandler = (e: SelectChangeEvent<unknown>) => {
    const selectedValue = e.target.value as string;
    setlanguage(selectedValue);
    localStorage.setItem("language", selectedValue.toLowerCase());
    window.location.reload();
  };
  const handleLogoutClick = () => {
    setDialogOpen(true);
  };

  const handleConfirmLogout = () => {
    setDialogOpen(false);
    setIsLoggedIn(false);
    const language = localStorage.getItem("language") || "en";
    localStorage.clear();
    localStorage.setItem("language",language);
    window.location.href = "/EmailAccountLoginBlock";
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  
  // Customizable Area Start
  const renderHeader = ()=>{
    return <>
      <HeaderContainer position="static">
      <HeaderToolbar>
        <HeaderLogo src={navLogo.default} alt="logo" />
        <RightContainer>
        {isLoggedIn && (
              <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
              <SearchIcon
                style={{
                  color: '#94A3B8',
                  position: 'absolute',
                  left: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  fontSize: '20px'
                }}
              />
              <input
                type="text"
                placeholder="Search"
                style={{
                  padding: '10px 10px 10px 35px', // Add left padding to make room for the icon
                  fontSize: '16px',
                  borderRadius: '20px',
                  border: '1px solid #CBD5E1',
                  width: '250px',
                  color: "#94A3B8"
                }}
              />
            </div>
            )}
          <CustomSelect
            value={language}
            inputProps={{ 'aria-label': 'Select Language' }}
            renderValue={(selected : any) => {
              const selectedOption = languageOptions.find((option) => option.value === selected);
              return (
                <ListItem>
                  <ListImg src={selectedOption?.image.default} alt={selectedOption?.alt} /> {capitalizeFirstLetter(selectedOption?.value ?? "")}
                </ListItem>
              );
            }}
            onChange={languageInputHandler}
          >
            {languageOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <ListItem>
                  <ListImg src={option.image.default} alt={option.alt} /> {option.label}
                </ListItem>
              </MenuItem>
            ))}
          </CustomSelect>
          { isLoggedIn &&
            <StyledLogoutButtonChanges onClick={handleLogoutClick}>
              {i18n.t("Request Changes")}
            </StyledLogoutButtonChanges>
          }

          { isLoggedIn &&
            <StyledLogoutButton onClick={handleLogoutClick}>
            <LogOutLogo src={logoutIcon.default } />{i18n.t("Log Out")}
          </StyledLogoutButton>
          }
        </RightContainer>
      </HeaderToolbar>
    </HeaderContainer>
    <CustomLogoutDialog
          isDialogOpen={isDialogOpen}
          handleCloseDialog={handleCloseDialog}
          handleConfirmLogout={handleConfirmLogout}
          title='Logout Confirmation'
          content='Are you sure you want to log out?'
          confrimButton='Log Out'
        />
  </>

   }
  // Customizable Area End
  function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  function setDefaultLanguage(){
    const selectedLanguage = localStorage.getItem('language') || "en";
    setlanguage(selectedLanguage);
    localStorage.setItem("language",selectedLanguage);
  }
  useEffect(() => {
    setDefaultLanguage()
    const token = localStorage.getItem("token") || "";
    const isLoggedInStatus = token ? true : false;
    setIsLoggedIn(isLoggedInStatus);
    
  },[]);

  return (
    // Customizable Area Start
    <>{renderHeader()}</>
    // Customizable Area End
  )
}

 // Customizable Area Start
const HeaderLogo = styled('img')(({ theme }) => ({
  height: '30px',
  width: "130px",
  marginRight: theme.spacing(2),
}));
const ListItem = styled('div')({
  display: 'flex',
  alignItems: 'center', 
  
});

const ListImg = styled('img')(({ theme }) => ({
  height: '20px',
  marginRight: '2px'
}))

const HeaderContainer = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  display:"flex",
  justifyContent:"space-between",
  height: "74px",
}));

const HeaderToolbar = styled(Toolbar)({
  display:"flex", justifyContent:"space-between",
})

const CustomSelect = styled(Select)(({ theme }) => ({
  fontFamily: 'Roboto',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  '& .MuiSelect-select': {
    color: '#053348',
  },
  '& .MuiMenuItem-root': {
    color: '#64748B',
  },
}));
const RightContainer = styled(Box)({
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  gap:"20px",
  marginTop:"5px",
})
const LogOutLogo = styled('img')(({ theme }) => ({
  height: '25px',
  width: "25px",
}));
const StyledLogoutButtonChanges = styled(Button)(({ theme }) => ({
  color: "white",
  fontWeight: "bold",
  border: "1px solid  #00496B",
  borderRadius: "5px",
  padding: "13px 15px",
  backgroundColor: "#00496B",
  textTransform: "none",
  '&:hover': {
    backgroundColor:  "#00496B",
    color: "#FFFFFF",
  },
}));
const StyledLogoutButton = styled(Button)(({ theme }) => ({
  color: "#00496B",
  fontWeight: "bold",
  border:"1px solid  #00496B",
  textTransform: "none",
  borderRadius: "5px",
  padding: "14px 30px",
}));
// Customizable Area End