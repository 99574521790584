import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  emailValue: any;
  token: any;
  enablePasswordField: boolean;
  btnConfirmPasswordShowHide: boolean;
  isError: boolean;
  errorMessage : string;
  errorStatus :  boolean;
  password : string;
  checkedLetter: boolean;
  checkedDigit: boolean;
  checkedLength: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class NewPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createPasswordApiCallId : any;
  newTitle:string =configJSON.newTitle;
  createText: string = configJSON.createText;
  passwordCondition1:string;
  passwordCondition2:string;
  passwordCondition3:string;
  btnText:string = configJSON.btnText
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {

      emailValue: "",
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      isError : false,
      errorMessage :"",
      errorStatus : false,
      password: "",
      checkedLength:false,
      checkedLetter:false,
      checkedDigit: false,
    };
    this.passwordCondition1 = configJSON.pass + configJSON.passwordCondition1;
    this.passwordCondition2 = configJSON.pass + configJSON.passwordCondition2;
    this.passwordCondition3 = configJSON.pass + configJSON.passwordCondition3;
    this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
      ];
      this.receive = this.receive.bind(this);
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
   // Customizable Area Start
   localStorage.removeItem("token");
   window.history.pushState(null ,"",window.location.href);
   window.addEventListener('popstate',this.handlePopState);
   // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const responseId = getName(MessageEnum.RestAPIResponceMessage);
    const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (responseId !== message.id) return;

    if (this.createPasswordApiCallId != null && this.createPasswordApiCallId === apiCallId) {
        this.handleOtpResponse(responseJson);
    } 
    // Customizable Area End
  }
  // Customizable Area Start
  async componentWillUnmount() {
    window.removeEventListener('popstate',this.handlePopState);
  }
  handlePopState = () => {
    window.history.pushState(null ,"",window.location.href);
  }
  handleOtpResponse(responseJson: any) {
    if (responseJson?.message !== "OTP verified successfully") {
        return;
    }
    const responseOTP = responseJson.meta;
    const user = {
      email: responseOTP.account.email,
      activate_account: responseOTP.account.activate_account,
      phone_number: responseOTP.account.phone_number,
      language: responseOTP.account.language,
      internal_code: responseOTP.account.internal_code,
      first_name: responseOTP.account.first_name,
      token: responseOTP.token,
  };
  localStorage.removeItem("otp");
  localStorage.setItem("token" ,JSON.stringify(responseOTP.token));
  localStorage.setItem("user", JSON.stringify(user));
  window.location.href = "/Dashboard"
  }
  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
    this.validatePasswordConditions(text);
  };
  handleClickShowPassword = () => {
    this.setState({
        enablePasswordField: !this.state.enablePasswordField,
      });
  }
  validatePasswordConditions = (password: string) => {
    const hasLetter = /[a-zA-Z]/.test(password); 
    const hasMinimumLength = password.length >= 8; 
    const hasDigit = /\d/.test(password); 
  
    this.setState({
      checkedLetter: hasLetter,
      checkedLength: hasMinimumLength,
      checkedDigit: hasDigit,
    });
  };
  createNewPassword(): boolean {
    if (

        !this.state.password ||
        !this.state.checkedLetter ||
        !this.state.checkedLength ||
        !this.state.checkedDigit 
      ) {
        return false;
      }
      const header = {
        "Content-Type": configJSON.forgotPasswordAPiContentType
      };
      const data = {
        email: localStorage.getItem("email"),
        password: this.state.password,
        otp:  localStorage.getItem("otp")
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createPasswordApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.CreateNewWebApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  // Customizable Area End
}
