// Customizable Area Start
import React, { createContext, useContext, useState, ReactNode } from 'react';


interface SearchContextType {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}


const SearchContext = createContext<SearchContextType | undefined>(undefined);


interface SearchProviderProps {
  children: ReactNode; 
}


export const SearchProvider: React.FC<SearchProviderProps> = ({ children }) => {
  const [searchValue, setSearchValue] = useState<string>('');

  return (
    <SearchContext.Provider value={{ searchValue, setSearchValue }}>
      {children}
    </SearchContext.Provider>
  );
};


export const useSearch = (): SearchContextType => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};


export const withSearch = (WrappedComponent: React.ComponentType<any>) => {
  return function WithSearchComponent(props: any) {
    const { searchValue } = useSearch();
    return <WrappedComponent {...props} searchValue={searchValue} />;
  };
};

// Customizable Area End