Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.activationTitle = "Activate Your Account";
exports.activationHeader = "Create your password to activate your account";
exports.emailLabel = "Email";
exports.emailPlaceholder = "Test@gmail.com";
exports.btnText = "Activate Account";
exports.agreeWith = "I agree with";
exports.termsAndConditions ="Privacy Policy and Terms and Conditions";
exports.pass = "Pass";
exports.word = "word";
exports.passwordCondition1 = "word must include at least one letter.";
exports.passwordCondition2 = "word must be at least 8 characters long.";
exports.passwordCondition3 = "word must include at least one digit.";
exports.newPass = "New Pass";
exports.passwordPlaceHolder = "@1234";
exports.webApiEndPoint = "account_block/activate_account_user"
exports.webApiMethodTypeActivateAccount = "PATCH"
exports.errorMessage = "You have to agree with Privacy Policy and Terms and Conditions to Sign up"
exports.notificationApiEndPoint = "/bx_block_terms_and_conditions/dashboard_terms_and_conditions"
// Customizable Area End
